@keyframes scale-up-down {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

.counter {
  animation: scale-up-down 2s ease-in-out;
}

.relative {
  position: relative;
}

.hideOverflow {
  overflow: hidden;
}

.mediaCursor {
  position: absolute;
  top: -20px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  pointer-events: none;
  background: var(--v-color-background-primary);
  border-radius: 50%;
  transition: opacity 300ms ease-in-out;
  inset-inline-start: -20px;
}

@media (--v-until-lg) {
  .mediaCursor {
    display: none;
  }
}

.opacityVisible {
  opacity: 1;
}

.opacityHidden {
  opacity: 0;
}

.pointerScreen {
  position: absolute;
  z-index: 1;
  width: 100%;
  cursor: none;
}

@media (--v-until-lg) {
  .pointerScreen {
    display: none;
  }
}

.sliderItem {
  width: 100%;
  height: 100%;
}

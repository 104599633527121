.container {
  overflow: hidden;
}

.mediaContainer {
  position: relative;
  width: calc(100% - var(--start-end-gutter));
  margin: 0 auto;
}

.hideAssetsSneakPeek {
  overflow: hidden;
}

.thumbnail {
  width: 72px;
  height: 54px;
}

.thumbnailImage {
  aspect-ratio: unset;
  border-color: transparent;

  &[aria-selected='true'] {
    border-color: var(--v-color-foreground-primary);
  }
}

.arrowWrapper {
  &:active {
    & > div {
      width: 20px;
      height: 20px;
    }
  }
}

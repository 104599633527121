.carouselArrow {
  position: absolute;
  top: calc(50% - 40px);
}

.carouselArrow[data-direction='left'] {
  inset-inline-start: var(--v-space-24);
}

.carouselArrow[data-direction='right'] {
  inset-inline-end: var(--v-space-24);
}

.carouselArrow:focus-visible {
  outline-color: var(--v-color-foreground-inverted);
}

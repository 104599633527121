.container {
  width: calc(100vw - var(--v-space-48));
}

.assetWrapper {
  position: relative;
}

.openOverlayButton {
  position: absolute;
  top: var(--v-space-16);
  z-index: 1;
  width: 40px;
  height: 40px;
  background: var(--v-color-background-primary) var(--expand-icon) no-repeat
    center !important;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 400ms ease;
  inset-inline-end: var(--v-space-16);
}

.openOverlayButton[aria-current='true'] {
  opacity: 1;
}

@media (--v-from-md) {
  .container {
    width: calc(100vw - 160px);
  }
}

@media (--v-from-lg) {
  .container {
    width: var(--desktop-carousel-container-width);
  }

  .openOverlayButton {
    top: var(--v-space-24);
    inset-inline-end: var(--v-space-24);
  }
}

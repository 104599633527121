.descriptionWrapper {
  max-width: 327px;
}

@media (--v-from-lg) {
  .mediaContainer {
    width: calc(100% - 2 * var(--v-space-48));
  }
  .descriptionWrapper {
    max-width: 600px;
  }
}
